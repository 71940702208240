<ons-page>
  <ons-card>
    <div class="title">
      Line-up
    </div>
  </ons-card>

  <div class="artists col-1-1 no-p">
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.groovesum.nl/wp-content/uploads/2017/03/Fijne-Deuntjes.jpg" alt="Fijne Deuntjes">
          <h5>Fijne Deuntjes: 17.00 - 19.30</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://pbs.twimg.com/profile_images/927600662640197634/rfDBrvBO.jpg" alt="Praia del Sol">
          <h5>Praia del Sol: 19.30 - 21.30</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://www.vikingentertainment.nl/uploads/product/groot/thom-bold-boeken-1548254345.jpg" alt="Thom Bold + saxofonist">
          <h5>Thom Bold + saxofonist: 21.30 - 23.00</h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://steam.dj/wp-content/uploads/2018/07/DFN-persfoto-2-1080px.jpg" alt="Dansado en de feestneger">
          <h5>Dansado en de feestneger: 23.00 - 00.30 </h5>
        </figure>
      </a>
    </div>
    <div class="col-1-3 sm-col-1-2 xs-col-1-1">
      <a href="javascript:void(0)" class="event-item col-1-1 no-p">
        <figure class="wrapper">
          <img src="https://static2.amsterdam-dance-event.nl/uploads/images/cache/2013/10/640x853q=90_billythekitADE_22889_22891_229x305_90_1_1_c.jpg" alt="Billy the Kit">
          <h5>Billy the Kit - 00.30 - 02.00</h5>
        </figure>
      </a>
    </div>
  </div>


</ons-page>
