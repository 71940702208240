<ons-page>
  <div *ngIf="currentPartyPicIndex < 0; else elseBlock" class="content">
    <ons-toolbar modifier="transparent" inline>
      <div class="center">Party-Pics</div>
      <div class="right">
        <!--<ons-button class="take-picture" (click)="fileImage.click()" >-->
        <ons-button class="take-picture" (click)="fileImage.click()" [hidden]="!showTakeImageButton">
          <ons-icon icon="md-upload"></ons-icon>
          Foto Maken
        </ons-button>
        <input type="file" accept="image/*" style="display: none" (change)="onFileImageChanged($event)" capture #fileImage>
      </div>
    </ons-toolbar>
    <div class="base-grid" *ngIf="partyPics && partyPics.length > 0">

      <div class="item-wrapper padded-container" *ngFor="let fullPictureUrl of partyPics; let i = index;">
        <img (click)="onPartyImageClicked(i)"  class="img centered" src="{{THUMBS_PATH + fullPictureUrl}}">
      </div>

      <!--<div class="item-wrapper padded-container">-->
        <!--<img class="img centered" src="http://via.placeholder.com/816x481">-->
      <!--</div>-->

      <!--<div class="item-wrapper padded-container">-->
        <!--<img class="img centered" src="http://via.placeholder.com/604x611">-->
      <!--</div>-->

      <!--<div class="item-wrapper padded-container">-->
        <!--<img class="img centered" src="http://via.placeholder.com/940x667">-->
      <!--</div>-->

    </div>


  </div>
  <ng-template #elseBlock><viewpicture-component [partyPics]="partyPics" [currentPartyPicIndex]="currentPartyPicIndex" (viewPictureFinished)="onViewPictureFinished($event)"></viewpicture-component></ng-template>
</ons-page>
