interface String {
  truncate(max: number, decorator: string): string;
}

interface Number {
  pad(size: number): string;
}

String.prototype.truncate = function(max, decorator){
  decorator = decorator || '...';
  return (this.length > max ? this.substring(0,max)+decorator : this);
};

Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
};
