export default class MainUtils {
  static hasClass(ele: any,cls: string) {
    return !!ele.className.match(new RegExp('(\\s|^)'+cls+'(\\s|$)'));
  }
  static addClass(ele: any,cls: string) {
    if (!this.hasClass(ele,cls)) ele.className += " "+cls;
  }
  static removeClass(ele: any,cls: string) {
    if (this.hasClass(ele,cls)) {
      var reg = new RegExp('(\\s|^)'+cls+'(\\s|$)');
      ele.className=ele.className.replace(reg,' ');
    }
  }
  static isNumber(value: string | number): boolean
  {
    return !isNaN(Number(value.toString()));
  }
}
