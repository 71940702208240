import {Field, Team, TeamEvent} from "@core/model/team.model";

export class Constants {
  public static ERROR_OBJECTS: Object = {
    required: '{{field}} is required',
    email: 'Please enter valid email'
  };
  public static MONTH_STRINGS: string[] = ["Januari", "Februari", "March", "April", "May", "June", "Juli", "August", "September", "October", "November", "December"];
  public static TEAMS: Team[] = [
    {id: 1, name: "Spiritus Vitalis en Pandora", poules: ["A"]},
    {id: 2, name: "Missdaad en Annorlunda", poules: ["A"]},
    {id: 3, name: "Dispuut Topaas en Dispuut Faunus", poules: ["A"]},
    {id: 4, name: "DD Persephone & OMSG Plutarchus", poules: ["A"]},
    {id: 5, name: "Pisimopsantee & Chouette", poules: ["A"]},
    {id: 6, name: "H.D. Nexus & D.D. Adelphi ", poules: ["A"]},
    {id: 7, name: "Po’di Ragazze en Vriendenclub S.O.S", poules: ["A"]},
    {id: 8, name: "E.M.O.D Kokkepel en MHD Rhino", poules: ["A"]},
    {id: 9, name: "Prometheus & Sororitas", poules: ["A"]},
    {id: 10, name: "O.D.D.M Les Sacripantes & Herendispuut Helios", poules: ["A"]},
    {id: 11, name: "O.D.D. Schanulleke & F.I.E.T.S.", poules: ["A"]},
    {id: 12, name: "Herenhuis Adrianus & Sirene", poules: ["A"]},
    {id: 13, name: "Omega & Hesperides ", poules: ["A"]},
    {id: 14, name: "H.D. Excessive en A.D.D. Phylla", poules: ["A"]},
    {id: 15, name: "MHG Librium & N.ON. Tilburg", poules: ["A"]},
    {id: 16, name: "THD. Bravado & Flair", poules: ["A"]},
    {id: 17, name: "Damesdispuut Alcmenae & Heerendispuut Lysias", poules: ["A"]},
    {id: 18, name: "AVD Dårfinkar en HLD Drôle", poules: ["A"]},
    {id: 19, name: "Minx & Gustibus", poules: ["A"]},
    {id: 20, name: "Perikles &Samodivi", poules: ["A"]},
    {id: 21, name: "O.D.D. Bon’Aparte + M.H.D. Vloeibaar Goud", poules: ["A"]},
    {id: 22, name: "MAX & ECI", poules: ["A"]},
    {id: 23, name: "Linque & Fabras", poules: ["A"]},
    {id: 24, name: "Pink wins & Que Pasa", poules: ["A"]},
    {id: 25, name: "D.D.A.M.T. Meridies & M.D.D. Cumbonadea", poules: ["A"]},
    {id: 26, name: "Jaarclub Formele & Quadripara", poules: ["A"]},
    {id: 27, name: "Allicht & Sunergos", poules: ["A"]},
    {id: 28, name: "M.H.D. Ambiorix & a Qoui Bon", poules: ["A"]},
    {id: 29, name: "Equinox en Chic Sat.", poules: ["A"]},
    {id: 30, name: "Primus Inter Paresen & M.J.D.D. Victoria", poules: ["A"]},
    {id: 31, name: "O.D.D. Amicitia en O.M.H. Cerberus", poules: ["A"]},
    {id: 32, name: "A.D.D. Popino en O.H.D. Los Hombres Locos", poules: ["A"]},
    {id: 33, name: "Femmes-Tastiques & TSG", poules: ["A"]},
    {id: 34, name: "M.D.D. Ex Aequo & Dispuut P.I.O.T (Tilburg)", poules: ["A"]},
    {id: 35, name: "Bonobo & Nyneve", poules: ["A"]},
    {id: 36, name: "DD PHENIX en AC Trega", poules: ["A"]},
    {id: 37, name: "Silenus & Les must", poules: ["A"]},
    {id: 38, name: "Red Devil & Watergeuzen", poules: ["A"]},
    {id: 39, name: "Viramitas & Maverick", poules: ["A"]},
    {id: 40, name: "Lucifer & Ritz", poules: ["A"]},
    {id: 41, name: "Diablo & V.E.R zuipen", poules: ["A"]},
    {id: 42, name: "Epicurus & Nos Ergo", poules: ["A"]},
    {id: 43, name: "Festglede & Medusa", poules: ["A"]},
    {id: 44, name: "Nondejuke & In Vino Veritas", poules: ["A"]},
    {id: 45, name: "Ipse Dixit & PEB (Plus est Bibendum)", poules: ["A"]},
    {id: 46, name: "Sogart Experiat & Papillion", poules: ["A"]},
    {id: 47, name: "E.O.O.O.H.D. Ormetikos en Daps Decentium", poules: ["A"]},

    {id: 41, name: "Winnaar A", poules: []},
    {id: 43, name: "Winnaar B", poules: []},
    {id: 45, name: "Winnaar C", poules: []},
    {id: 47, name: "Winnaar D", poules: []},
    {id: 42, name: "Winnaar  E", poules: []},
    {id: 44, name: "Winnaar  F", poules: []},
    {id: 46, name: "Winnaar  G", poules: []},
    {id: 48, name: "Winnaar  H", poules: []},
    {id: 49, name: "Winnaar ¼ finale 1", poules: []},
    {id: 51, name: "Winnaar ¼ finale 2", poules: []},
    {id: 50, name: "Winnaar ¼ finale 3", poules: []},
    {id: 52, name: "Winnaar ¼ finale 4", poules: []},
    {id: 53, name: "Winnaar ½ finale 1", poules: []},
    {id: 54, name: "Winnaar ½ finale 2", poules: []},
  ];

  public static FIELDS: Field[] = [
    {id: 1, name: "Veld FM"},
    {id: 2, name: "Veld Integrand"},
    {id: 3, name: "Veld Carnal"},
    {id: 4, name: "Veld Beez"},
    {id: 5, name: "Veld Beurs"},
    {id: 6, name: "Veld Brand New Day"},
    {id: 7, name: "Veld Coenen "},
    // {id: 99, name: "Bier Eiland"},
    // {id: 98, name: "Pizza"},
    // {id: 97, name: "Friet"},
    // {id: 96, name: "Tent Stage"},
    // {id: 95, name: "Tent Stage Bar"},
    // {id: 94, name: "WC"},
    // {id: 93, name: "Bonnen"},
    // {id: 92, name: "EHBO"},
    // {id: 91, name: "Ingang"},
    // {id: 90, name: "Wedstrijd Tafel"},
     {id: 89, name: "Sangria Bar"},
    // {id: 88, name: "Main Stage"},
     {id: 87, name: "Wiggely Woggely"},
  ];

  public static MATCHES: TeamEvent[] = [
    {id:1   ,description: '', team1Id: 1 , team2Id: 2 , fieldId: 1 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:2   ,description: '', team1Id: 3 , team2Id: 4 , fieldId: 1 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:3   ,description: '', team1Id: 5 , team2Id: 6 , fieldId: 1 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:4   ,description: '', team1Id: 1 , team2Id: 4 , fieldId: 1 , startingHour: 17 , startingMinute: 0 , durationInMinutes:30 },
    {id:5   ,description: '', team1Id: 3 , team2Id: 6 , fieldId: 1 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:6   ,description: '', team1Id: 46 , team2Id: 16 , fieldId: 1 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:7   ,description: '', team1Id: 2 , team2Id: 5 , fieldId: 1 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:8   ,description: '', team1Id: 1 , team2Id: 3 , fieldId: 1 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:9   ,description: '', team1Id: 4 , team2Id: 5 , fieldId: 1 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:10   ,description: '', team1Id: 2 , team2Id: 6 , fieldId: 1 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:11   ,description: '', team1Id: 7 , team2Id: 8 , fieldId: 2 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:12   ,description: '', team1Id: 9 , team2Id: 10 , fieldId: 2 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:13   ,description: '', team1Id: 11 , team2Id: 12 , fieldId: 2 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:14   ,description: '', team1Id: 7 , team2Id: 10 , fieldId: 2 , startingHour: 17 , startingMinute: 0 , durationInMinutes:30 },
    {id:15   ,description: '', team1Id: 9 , team2Id: 12 , fieldId: 2 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:16   ,description: '', team1Id: 8 , team2Id: 11 , fieldId: 2 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:17   ,description: '', team1Id: 7 , team2Id: 9 , fieldId: 2 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:18   ,description: '', team1Id: 10 , team2Id: 11 , fieldId: 2 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:19   ,description: '', team1Id: 8 , team2Id: 12 , fieldId: 2 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:20   ,description: '', team1Id: 13 , team2Id: 14 , fieldId: 3 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:21   ,description: '', team1Id: 15 , team2Id: 42 , fieldId: 3 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:22   ,description: '', team1Id: 45 , team2Id: 18 , fieldId: 3 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:23   ,description: '', team1Id: 13 , team2Id: 42 , fieldId: 3 , startingHour: 17 , startingMinute: 0 , durationInMinutes:30 },
    {id:24   ,description: '', team1Id: 44 , team2Id: 17 , fieldId: 3 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:25   ,description: '', team1Id: 15 , team2Id: 18 , fieldId: 3 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:26   ,description: '', team1Id: 14 , team2Id: 45 , fieldId: 3 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:27   ,description: '', team1Id: 13 , team2Id: 15 , fieldId: 3 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:28   ,description: '', team1Id: 42 , team2Id: 45 , fieldId: 3 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:29   ,description: '', team1Id: 14 , team2Id: 18 , fieldId: 3 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:30   ,description: '', team1Id: 19 , team2Id: 20 , fieldId: 4 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:31   ,description: '', team1Id: 21 , team2Id: 22 , fieldId: 4 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:32   ,description: '', team1Id: 23 , team2Id: 24 , fieldId: 4 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:33   ,description: '', team1Id: 19 , team2Id: 22 , fieldId: 4 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:34   ,description: '', team1Id: 21 , team2Id: 24 , fieldId: 4 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:35   ,description: '', team1Id: 20 , team2Id: 23 , fieldId: 4 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:36   ,description: '', team1Id: 19 , team2Id: 21 , fieldId: 4 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:37   ,description: '', team1Id: 22 , team2Id: 23 , fieldId: 4 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:38   ,description: '', team1Id: 20 , team2Id: 24 , fieldId: 4 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:39   ,description: '', team1Id: 25 , team2Id: 26 , fieldId: 5 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:40   ,description: '', team1Id: 27 , team2Id: 28 , fieldId: 5 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:41   ,description: '', team1Id: 29 , team2Id: 35 , fieldId: 5 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:42   ,description: '', team1Id: 26 , team2Id: 27 , fieldId: 5 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:43   ,description: '', team1Id: 28 , team2Id: 29 , fieldId: 5 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:44   ,description: '', team1Id: 25 , team2Id: 27 , fieldId: 5 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:45   ,description: '', team1Id: 26 , team2Id: 28 , fieldId: 5 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:46   ,description: '', team1Id: 25 , team2Id: 29 , fieldId: 5 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:47   ,description: '', team1Id: 30 , team2Id: 32 , fieldId: 6 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:48   ,description: '', team1Id: 33 , team2Id: 34 , fieldId: 6 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:49   ,description: '', team1Id: 43 , team2Id: 44 , fieldId: 6 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:50   ,description: '', team1Id: 32 , team2Id: 33 , fieldId: 6 , startingHour: 17 , startingMinute: 0 , durationInMinutes:30 },
    {id:51   ,description: '', team1Id: 34 , team2Id: 35 , fieldId: 6 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:52   ,description: '', team1Id: 30 , team2Id: 33 , fieldId: 6 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:53   ,description: '', team1Id: 43 , team2Id: 17 , fieldId: 6 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:54   ,description: '', team1Id: 32 , team2Id: 34 , fieldId: 6 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:55   ,description: '', team1Id: 30 , team2Id: 35 , fieldId: 6 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:56   ,description: '', team1Id: 43 , team2Id: 16 , fieldId: 6 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:57   ,description: '', team1Id: 37 , team2Id: 38 , fieldId: 7 , startingHour: 15 , startingMinute: 30 , durationInMinutes:30 },
    {id:58   ,description: '', team1Id: 40 , team2Id: 41 , fieldId: 7 , startingHour: 16 , startingMinute: 0 , durationInMinutes:30 },
    {id:59   ,description: '', team1Id: 17 , team2Id: 46 , fieldId: 7 , startingHour: 16 , startingMinute: 30 , durationInMinutes:30 },
    {id:60   ,description: '', team1Id: 47 , team2Id: 16 , fieldId: 7 , startingHour: 17 , startingMinute: 0 , durationInMinutes:30 },
    {id:61   ,description: '', team1Id: 38 , team2Id: 40 , fieldId: 7 , startingHour: 17 , startingMinute: 30 , durationInMinutes:30 },
    {id:62   ,description: '', team1Id: 41 , team2Id: 47 , fieldId: 7 , startingHour: 18 , startingMinute: 0 , durationInMinutes:30 },
    {id:63   ,description: '', team1Id: 37 , team2Id: 40 , fieldId: 7 , startingHour: 18 , startingMinute: 30 , durationInMinutes:30 },
    {id:64   ,description: '', team1Id: 38 , team2Id: 41 , fieldId: 7 , startingHour: 19 , startingMinute: 0 , durationInMinutes:30 },
    {id:65   ,description: '', team1Id: 44 , team2Id: 46 , fieldId: 7 , startingHour: 19 , startingMinute: 30 , durationInMinutes:30 },
    {id:66   ,description: '', team1Id: 37 , team2Id: 47 , fieldId: 7 , startingHour: 20 , startingMinute: 0 , durationInMinutes:30 },

    {id:66   ,description: '', team1Id: 42 , team2Id: 18 , fieldId: 87 , startingHour: 15 , startingMinute: 30 , durationInMinutes:15  },
    {id:67   ,description: '', team1Id: 41 , team2Id: 46 , fieldId: 87 , startingHour: 15 , startingMinute: 45 , durationInMinutes:15  },
    {id:68   ,description: '', team1Id: 7 , team2Id: 11 , fieldId: 87 , startingHour: 16 , startingMinute: 0 , durationInMinutes:15  },
    {id:69   ,description: '', team1Id: 26 , team2Id: 32 , fieldId: 87 , startingHour: 16 , startingMinute: 15 , durationInMinutes:15  },
    {id:70   ,description: '', team1Id: 20 , team2Id: 21 , fieldId: 87 , startingHour: 16 , startingMinute: 30 , durationInMinutes:15  },
    {id:71   ,description: '', team1Id: 2 , team2Id: 3 , fieldId: 87 , startingHour: 16 , startingMinute: 45 , durationInMinutes:15  },
    {id:72   ,description: '', team1Id: 8 , team2Id: 9 , fieldId: 87 , startingHour: 17 , startingMinute: 0 , durationInMinutes:15  },
    {id:73   ,description: '', team1Id: 38 , team2Id: 44 , fieldId: 87 , startingHour: 17 , startingMinute: 15 , durationInMinutes:15  },
    {id:74   ,description: '', team1Id: 14 , team2Id: 15 , fieldId: 87 , startingHour: 17 , startingMinute: 30 , durationInMinutes:15  },
    {id:75   ,description: '', team1Id: 37 , team2Id: 43 , fieldId: 87 , startingHour: 17 , startingMinute: 45 , durationInMinutes:15  },
    {id:76   ,description: '', team1Id: 19 , team2Id: 23 , fieldId: 87 , startingHour: 18 , startingMinute: 0 , durationInMinutes:15  },
    {id:77   ,description: '', team1Id: 10 , team2Id: 12 , fieldId: 87 , startingHour: 18 , startingMinute: 15 , durationInMinutes:15  },
    {id:78   ,description: '', team1Id: 13 , team2Id: 45 , fieldId: 87 , startingHour: 18 , startingMinute: 30 , durationInMinutes:15  },
    {id:79   ,description: '', team1Id: 22 , team2Id: 24 , fieldId: 87 , startingHour: 18 , startingMinute: 45 , durationInMinutes:15  },
    {id:80   ,description: '', team1Id: 4 , team2Id: 6 , fieldId: 87 , startingHour: 19 , startingMinute: 0 , durationInMinutes:15  },
    {id:81   ,description: '', team1Id: 47 , team2Id: 40 , fieldId: 87 , startingHour: 19 , startingMinute: 15 , durationInMinutes:15  },
    {id:82   ,description: '', team1Id: 27 , team2Id: 29 , fieldId: 87 , startingHour: 19 , startingMinute: 30 , durationInMinutes:15  },
    {id:83   ,description: '', team1Id: 17 , team2Id: 16 , fieldId: 87 , startingHour: 19 , startingMinute: 45 , durationInMinutes:15  },
    {id:84   ,description: '', team1Id: 35 , team2Id: 33 , fieldId: 87 , startingHour: 20 , startingMinute: 0 , durationInMinutes:15  },
    {id:85   ,description: '', team1Id: 1 , team2Id: 5 , fieldId: 87 , startingHour: 20 , startingMinute: 15 , durationInMinutes:15  },
    {id:86   ,description: '', team1Id: 25 , team2Id: 30 , fieldId: 87 , startingHour: 20 , startingMinute: 30 , durationInMinutes:15  },
    {id:87   ,description: '', team1Id: 28 , team2Id: 34 , fieldId: 87 , startingHour: 20 , startingMinute: 45 , durationInMinutes:15  },

    {id:88   ,description: '', team1Id: 3 , team2Id: 5 , fieldId: 87 , startingHour: 15 , startingMinute: 30 , durationInMinutes:15  },
    {id:89   ,description: '', team1Id: 44 , team2Id: 16 , fieldId: 87 , startingHour: 15 , startingMinute: 45 , durationInMinutes:15  },
    {id:90   ,description: '', team1Id: 13 , team2Id: 18 , fieldId: 87 , startingHour: 16 , startingMinute: 0 , durationInMinutes:15  },
    {id:91   ,description: '', team1Id: 30 , team2Id: 34 , fieldId: 87 , startingHour: 16 , startingMinute: 15 , durationInMinutes:15  },
    {id:92   ,description: '', team1Id: 14 , team2Id: 42 , fieldId: 87 , startingHour: 16 , startingMinute: 30 , durationInMinutes:15  },
    {id:93   ,description: '', team1Id: 37 , team2Id: 41 , fieldId: 87 , startingHour: 16 , startingMinute: 45 , durationInMinutes:15  },
    {id:94   ,description: '', team1Id: 25 , team2Id: 28 , fieldId: 87 , startingHour: 17 , startingMinute: 0 , durationInMinutes:15  },
    {id:95   ,description: '', team1Id: 15 , team2Id: 45 , fieldId: 87 , startingHour: 17 , startingMinute: 15 , durationInMinutes:15  },
    {id:96   ,description: '', team1Id: 43 , team2Id: 7 , fieldId: 87 , startingHour: 17 , startingMinute: 30 , durationInMinutes:15  },
    {id:97   ,description: '', team1Id: 2 , team2Id: 4 , fieldId: 87 , startingHour: 17 , startingMinute: 45 , durationInMinutes:15  },
    {id:98   ,description: '', team1Id: 20 , team2Id: 22 , fieldId: 87 , startingHour: 18 , startingMinute: 0 , durationInMinutes:15  },
    {id:99   ,description: '', team1Id: 1 , team2Id: 6 , fieldId: 87 , startingHour: 18 , startingMinute: 15 , durationInMinutes:15  },
    {id:100   ,description: '', team1Id: 7 , team2Id: 12 , fieldId: 87 , startingHour: 18 , startingMinute: 30 , durationInMinutes:15  },
    {id:101   ,description: '', team1Id: 26 , team2Id: 29 , fieldId: 87 , startingHour: 18 , startingMinute: 45 , durationInMinutes:15  },
    {id:102   ,description: '', team1Id: 8 , team2Id: 10 , fieldId: 87 , startingHour: 19 , startingMinute: 0 , durationInMinutes:15  },
    {id:103   ,description: '', team1Id: 27 , team2Id: 33 , fieldId: 87 , startingHour: 19 , startingMinute: 15 , durationInMinutes:15  },
    {id:104   ,description: '', team1Id: 19 , team2Id: 24 , fieldId: 87 , startingHour: 19 , startingMinute: 30 , durationInMinutes:15  },
    {id:105   ,description: '', team1Id: 47 , team2Id: 38 , fieldId: 87 , startingHour: 19 , startingMinute: 45 , durationInMinutes:15  },
    {id:106   ,description: '', team1Id: 9 , team2Id: 11 , fieldId: 87 , startingHour: 20 , startingMinute: 0 , durationInMinutes:15  },
    {id:107   ,description: '', team1Id: 21 , team2Id: 23 , fieldId: 87 , startingHour: 20 , startingMinute: 15 , durationInMinutes:15  },
    {id:108   ,description: '', team1Id: 35 , team2Id: 32 , fieldId: 87 , startingHour: 20 , startingMinute: 30 , durationInMinutes:15  },
    {id:109   ,description: '', team1Id: 40 , team2Id: 17 , fieldId: 87 , startingHour: 20 , startingMinute: 45 , durationInMinutes:15  },

    {id:110 ,description: '', team1Id: 41, team2Id: 42, fieldId: 1, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:111 ,description: '', team1Id: 43, team2Id: 44, fieldId: 2, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:112 ,description: '', team1Id: 45, team2Id: 46, fieldId: 3, startingHour: 20, startingMinute: 45, durationInMinutes: 30},
    {id:113 ,description: '', team1Id: 47, team2Id: 48, fieldId: 4, startingHour: 20, startingMinute: 45, durationInMinutes: 30},

    {id:114 ,description: '', team1Id: 49, team2Id: 50, fieldId: 1, startingHour: 21, startingMinute: 15, durationInMinutes: 30},
    {id:115 ,description: '', team1Id: 51, team2Id: 52, fieldId: 2, startingHour: 21, startingMinute: 15, durationInMinutes: 30},

    {id:116 ,description: '', team1Id: 53, team2Id: 54, fieldId: 1, startingHour: 22, startingMinute: 0, durationInMinutes: 30},
  ];
}
