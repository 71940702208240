import {AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, Subject} from "rxjs";
import * as ons from "onsenui";
import {ImageService} from "@core/service/image.service";
import {environment} from "@env/environment";
import MainUtils from "@app/utils/mainutils";

@Component({
  selector: 'viewpicture-component',
  templateUrl: './view-picture.component.html',
  styleUrls: ['./view-picture.component.scss']
})
export class ViewPictureComponent implements OnInit, AfterContentInit {
  @ViewChild('imageCarouselRef') imageCarouselRef: ElementRef;
  @Output() viewPictureFinished = new EventEmitter<boolean>();
  @Input() public partyPics: string[];
  @Input() public currentPartyPicIndex: number;

  public partyPicsIndexesLoaded: Set<number> = new Set<number>();

  public IMAGES_PATH = environment.serverUrl + "imageuploads/";

  constructor(public router: Router, public imageService: ImageService) {

  }

  public ngOnInit(): void {
  }
  public ngAfterContentInit(): void {
    // debugger;
    // this.imageCarouselRef.nativeElement.setActiveIndex(this.currentPartyPicIndex);
  }
  public previousImageCarousel() {

  }

  public nextImageCarousel() {

  }
//als op laatste index van partyPics
  public postChangeCarousel(e: any) {
    if(!(this.partyPics && this.partyPics.length > 0))
      return;

    this.partyPicsIndexesLoaded.add(e.activeIndex);

    this.currentPartyPicIndex = e.activeIndex;
    //e.carousel.setActiveIndex
    // if(e.activeIndex > e.lastActiveIndex) { //next
    //   this.currentPartyPicIndex++;
    // } else if(e.activeIndex < e.lastActiveIndex) { //previous
    //   this.currentPartyPicIndex--;
    // } else { //the same
    //
    // }
  }

  public goBack() {
    this.viewPictureFinished.emit(true);
  }

  public overscrollCarousel(e: any) {
    if(!(this.partyPics && this.partyPics.length > 0))
      return;

    if(e.direction === 'left') {

    } else if(e.direction === 'right') {

    }
  }

  public onCarouselImgLoaded(e: any) {
    if(e.target.width > e.target.height) {
      MainUtils.addClass(e.target, 'with-larger-then-height');
    } else {
      MainUtils.addClass(e.target, 'normal');
    }
  }


  public afterIfCarousel(e: any) {
    if(this.imageCarouselRef && this.imageCarouselRef.nativeElement)
      this.imageCarouselRef.nativeElement.setActiveIndex(this.currentPartyPicIndex);
  }
}
