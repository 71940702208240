import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {User} from "@core/model/user.model";
import {BaseAPIClass} from "@core/class/baseAPI.class";

@Injectable()
export class AuthenticationService extends BaseAPIClass{
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

    constructor(protected httpClient: HttpClient) {
      super(httpClient);
      this.baseUrl = 'login';

      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
    }

  login(password: string) {
    return this.create({ PASSWORD: password })
      .pipe(map(loginResult => {
        var user = new User();
        // login successful if there's a jwt token in the response
        // if (user && user.token) {
        //   // store user details and jwt token in local storage to keep user logged in between page refreshes
        //   localStorage.setItem('currentUser', JSON.stringify(user));
        //   this.currentUserSubject.next(user);
        // }

        return user;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
