<ons-page>
  <ons-card>
    <div class="title">
      Informatie
    </div>
  </ons-card>

  <ons-card>
    <div class="title">
      Organisatie
    </div>
    <div class="content">
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Lauren Coenen
          <a href="tel:+31621198274"><span class="badge badge-primary badge-pill">+31621198274</span></a>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Valerie Vaessen
          <a href="tel:+31613158071"><span class="badge badge-primary badge-pill">+31613158071</span></a>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Julie Hameleers
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Bram Arts
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Emil van der Par
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Thomas Waltmans
        </li>
      </ul>
    </div>
  </ons-card>

  <!--<ons-card>-->
    <!--<div class="title">-->
      <!--Contact-->
    <!--</div>-->
    <!--<div class="content">-->
      <!--<ons-row class="header">-->
        <!--<ons-col width="50%">Henk Jan</ons-col>-->
        <!--<ons-col width="50%">0646986487</ons-col>-->
      <!--</ons-row>-->
      <!--<ons-row>-->
        <!--<ons-col width="50%">Barry butsers</ons-col>-->
        <!--<ons-col width="50%">0619864873</ons-col>-->
      <!--</ons-row>-->
      <!--<ons-row>-->
        <!--<ons-col width="50%">Marit Ritsema</ons-col>-->
        <!--<ons-col width="50%"></ons-col>-->
      <!--</ons-row>-->
      <!--<ons-row>-->
        <!--<ons-col width="50%">Berry Brovenboom</ons-col>-->
        <!--<ons-col width="50%"></ons-col>-->
      <!--</ons-row>-->
      <!--<ons-row>-->
        <!--<ons-col width="50%">Roel Konor</ons-col>-->
        <!--<ons-col width="50%"></ons-col>-->
      <!--</ons-row>-->
      <!--<ons-row>-->
        <!--<ons-col width="50%">Joe Moesma</ons-col>-->
        <!--<ons-col width="50%"></ons-col>-->
      <!--</ons-row>-->
    <!--</div>-->
  <!--</ons-card>-->

  <ons-card>
    <div class="title">
      Site gemaakt door:
    </div>
    <div class="content">
      <a href="https://kleinprodesign.nl/">Bram Klein</a>
    </div>
  </ons-card>

  <!--<ons-card #sponsorsCard>-->
    <!--<div class="title">-->
      <!--Sponsoren-->
    <!--</div>-->
    <!--<div class="content">-->
      <!--<img class="sponsors-image" src="assets/images/sponsors.png">-->
    <!--</div>-->
  <!--</ons-card>-->

</ons-page>
