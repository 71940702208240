<ons-page>
  <ons-card>
    <div class="title">
      Plattegrond
    </div>
    <div class="content">
    </div>
  </ons-card>

  <div id='mainmap'></div>

</ons-page>
