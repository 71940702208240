<ons-page>
  <ons-splitter>
    <ons-splitter-side side="left" width="300px" swipeable collapse #leftMenuRef>
      <ons-page>
        <ons-toolbar>
          <div class="center">Menu</div>
          <div class="left">
            <ons-toolbar-button (click)="leftMenuRef.close()">
              <ons-icon icon="ion-navicon, material:md-menu"></ons-icon>
            </ons-toolbar-button>
          </div>
        </ons-toolbar>
        <div class="content">
          <img (click)="onTopImageMenuClick()" class="top-menu-image" src="assets/images/menu/menubackground.png" >
          <ons-list>
            <ons-list-item (click)="onMyTeamClick()" modifier="chevron" tappable>
              <div class="left">
                <ons-icon icon="md-accounts" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Mijn Team
              </div>
            </ons-list-item>

            <ons-list-item (click)="onFieldClick()" modifier="chevron" tappable>
              <div class="left">
                <!--<ons-icon icon="md-view-comfy" class="list-item__icon"></ons-icon>-->
                <ons-icon icon="md-view-carousel" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Velden
              </div>
            </ons-list-item>

            <ons-list-item (click)="onMapClick()" modifier="chevron" tappable>
              <div class="left">
                <ons-icon icon="md-map" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Plattegrond
              </div>
            </ons-list-item>

            <!--<ons-list-item (click)="onPriceListClick()" modifier="chevron" tappable>-->
              <!--<div class="left">-->
                <!--<ons-icon icon="md-money-box" class="list-item__icon"></ons-icon>-->
              <!--</div>-->
              <!--<div class="center">-->
                <!--Prijs-lijst-->
              <!--</div>-->
            <!--</ons-list-item>-->

            <!--<ons-list-item (click)="onActionsClick()" modifier="chevron" tappable>-->
              <!--<div class="left">-->
                <!--<ons-icon icon="md-time" class="list-item__icon"></ons-icon>-->
              <!--</div>-->
              <!--<div class="center">-->
                <!--Acties-->
              <!--</div>-->
            <!--</ons-list-item>-->

            <ons-list-item (click)="onPartyPicsClick()" modifier="chevron" tappable>
              <div class="left">
                <ons-icon icon="md-slideshow" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Party-Pics
              </div>
            </ons-list-item>

            <ons-list-item (click)="onArtistsClick()" modifier="chevron" tappable>
              <div class="left">
                <ons-icon icon="md-mood" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Artiesten
              </div>
            </ons-list-item>

            <ons-list-item (click)="onInformationClick()" modifier="chevron" tappable>
              <div class="left">
                <ons-icon icon="md-info-outline" class="list-item__icon"></ons-icon>
              </div>
              <div class="center">
                Informatie
              </div>
            </ons-list-item>

            <!--<ons-list-header>Thumbnails and titles</ons-list-header>-->
            <!--<ons-list-item modifier="chevron" tappable>-->
              <!--<div class="left">-->
                <!--<img class="list-item__thumbnail" src="http://placekitten.com/g/40/40">-->
              <!--</div>-->
              <!--<div class="center">-->
                <!--<span class="list-item__title">Cutest kitty</span><span class="list-item__subtitle">On the Internet</span>-->
              <!--</div>-->
            <!--</ons-list-item>-->


          </ons-list>
        </div>
      </ons-page>

        <!--<ons-list>-->
          <!--<ons-list-header>Menu</ons-list-header>-->
          <!--<ons-list-item modifier="longdivider" tappable *ngFor="let i of [1, 2, 3, 4]">-->
            <!--Menu item #{{i}}-->
          <!--</ons-list-item>-->
        <!--</ons-list>-->
    </ons-splitter-side>

    <ons-splitter-content>
      <ons-page>
        <ons-toolbar>
          <div class="center"><strong>{{title}}</strong></div>
          <div class="left" (click)="leftMenuRef.open()">
            <ons-toolbar-button>
              <ons-icon icon="ion-navicon, material:md-menu" size="35px"></ons-icon>
            </ons-toolbar-button>
          </div>
        </ons-toolbar>

        <div class="content">
          <!-- ============================================================== -->
          <!-- Main wrapper - style you can find in pages.scss -->
          <!-- ============================================================== -->
          <div id="main-wrapper">
          <!-- ============================================================== -->
          <!-- Page wrapper  -->
          <!-- ============================================================== -->
          <div class="page-wrapper">
          <!-- ============================================================== -->
          <!-- Container fluid  -->
          <!-- ============================================================== -->
          <div class="container-fluid">
          <app-breadcrumb></app-breadcrumb>
          <!-- ============================================================== -->
          <!-- Start Page Content -->
          <!-- ============================================================== -->
          <router-outlet></router-outlet>
          <!-- ============================================================== -->
          <!-- End Start Page Content -->
          <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Container fluid  -->
          <!-- ============================================================== -->
          </div>
          <!-- ============================================================== -->
          <!-- End Page wrapper  -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- footer -->
          <!-- ============================================================== -->

          <!-- ============================================================== -->
          <!-- End footer -->
          <!-- ============================================================== -->
          </div>
        </div>
        <!--<ons-tabbar>-->
        <!--<div class="tabbar">-->
        <!--<ons-tab label="Home" icon="ion-home" [routerLink]="['messages']" active></ons-tab>-->
        <!--<ons-tab label="Messages" icon="envelope"></ons-tab>-->
        <!--<ons-tab label="Events" icon="calendar"></ons-tab>-->
        <!--<ons-tab label="Settings" icon="cog"></ons-tab>-->
        <!--</div>-->
        <!--</ons-tabbar>-->

      </ons-page>
    </ons-splitter-content>
  </ons-splitter>


</ons-page>
