import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as ons from "onsenui";
import {Field, Team, TeamEvent} from "@core/model/team.model";
import {Constants} from "@app/core";
import MainUtils from "@app/utils/mainutils";
import {TeamManager} from "@core/model/team.manager";

@Component({
  selector: 'field-component',
  templateUrl: './field.component.html',
  styleUrls: ['../myteam/myteam.component.scss']
})
export class FieldComponent implements OnInit {
  public selectedField: Field;
  public allMatchesOfField: TeamEvent[] = null;

  private inputIdSub: any;

  get Teams(): Team[] {
    return Constants.TEAMS;
  }
  get Fields(): Field[] {
    return Constants.FIELDS;
  }

  get SelectedField(): Field {
    if(this.selectedField)
      return this.selectedField;
    return null;
  }
  get SelectedFieldName(): string {
    let selectedTeamInternal = this.SelectedField;

    if(selectedTeamInternal){
      return this.selectedField.name;
    }
    return "-";
  }

  constructor(public router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.inputIdSub = this.route.params.subscribe(params => {
      if(params['id'])
        this.trySetField(+params['id'])
    });
  }


  private loadField(fieldId: number) {
    this.allMatchesOfField = TeamManager.getAllMatchesOfField(fieldId);
  }

  public trySetField(possibleFieldId: any) {
    if(possibleFieldId && MainUtils.isNumber(possibleFieldId) && +possibleFieldId > -1) {
      let fieldId = +possibleFieldId;
      this.selectedField = this.Fields.filter((t) => t.id === fieldId)[0];
      if(this.selectedField)
        this.loadField(fieldId);
    } else {
      this.allMatchesOfField = null;
      this.selectedField = null;
    }
  }

  public onFieldChange(e: any) {
    this.trySetField(e.target ? e.target.value : null)
  }

  public getTeamNameFromId(teamId: number): string {
    let foundField: Field = this.Teams.filter((team) => { return team.id === teamId})[0];
    if(foundField)
      return foundField.name;
  }

}
