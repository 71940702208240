import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/core/class/baseAPI.class';

@Injectable()
export class ImageService extends BaseAPIClass {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = 'imagery';
  }

  public getAllImages() {
    return this.getAll()
  }

  public saveImage(base64Image: string, imageMimeType: string = "image/jpeg") {

    return this.create({Base64Image: base64Image, MimeType: imageMimeType});
  }
}
