import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";
import {AuthenticationService} from "@core/service/authentication.service";

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(public router: Router,
              public authenticationService: AuthenticationService) {}

  ngOnInit() {

  }

  onLoginClick() {
    //this.authenticationService.login();
  }
}
