import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";
import MainUtils from "@app/utils/mainutils";

@Component({
  selector: 'artists-component',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {
  constructor(public router: Router) {}

  public ngOnInit() {

  }

  public overscrollCarousel(e: any) {
    if(e.direction === 'left') {

    } else if(e.direction === 'right') {

    }
  }

  public postChangeCarousel(e: any) {
    //e.activeIndex;
  }
}
