import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, Output} from '@angular/core';

@Directive({selector: '[after-if]'})
export class AfterIfDirective implements AfterContentInit {
  @Output('after-if')
  public after: EventEmitter<AfterIfDirective> = new EventEmitter();

  public ngAfterContentInit(): void {
    setTimeout(()=>{
      // timeout helps prevent unexpected change errors
      this.after.next(this);
    });
  }
}
