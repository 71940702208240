<ons-page>
  <ons-card>
    <div class="title">
      Inloggen
    </div>
  </ons-card>

  <ons-card>
    <div class="title">
      Organisatie
    </div>
    <div class="content">
      <div class="login-form">
        <p>
          <input type="text" class="text-input--underbar" placeholder="Password" value="">
        </p>
        <ons-button modifier="large" class="login-button" (click)="onLoginClick()">Log In</ons-button>
      </div>
    </div>
  </ons-card>
</ons-page>
