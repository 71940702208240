import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";
import {Subject,interval, Observable, fromEvent } from "rxjs";
import { map, delay, filter, debounceTime, bufferWhen, switchAll } from 'rxjs/operators';
import {pipe} from "rxjs/internal-compatibility";

@Component({
  selector: 'information-component',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, AfterViewInit {
  @ViewChild('sponsorsCard') sponsorsCardRef: ElementRef;

  constructor(public router: Router) {}

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    let sponsorsCardObservables$: Observable<Event> = fromEvent<Event>(this.sponsorsCardRef.nativeElement, 'click')
    let sponsorsCardStream$: any = sponsorsCardObservables$
    .pipe(bufferWhen(() => sponsorsCardObservables$.pipe(delay(1500)))) // <--------- during 400ms
      .pipe(filter(events => events.length >= 5)) // <-------- 5 or more events
      .subscribe((res) => {
        this.router.navigate(['/login']);
      });
  }

}
