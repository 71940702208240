  <!--<ons-toolbar modifier="transparent" inline>-->
  <ons-toolbar modifier="transparent">
    <div class="left">
      <ons-toolbar-button (click)="goBack()">
        <ons-icon icon="md-arrow-left" size="2x"></ons-icon>
        <div>Terug</div>
      </ons-toolbar-button>
    </div>
    <div class="center">Afbeeldingen</div>
    <!--<div class="right">-->
      <!--<ons-toolbar-button (click)="nextImageCarousel()">-->
        <!--<ons-icon icon="md-chevron-right"></ons-icon>-->
      <!--</ons-toolbar-button>-->
    <!--</div>-->
  </ons-toolbar>

  <ons-carousel *ngIf="partyPics && partyPics.length > 0" (after-if)="afterIfCarousel($event)" (overscroll)="overscrollCarousel($event)" (postchange)="postChangeCarousel($event)" fullscreen swipeable auto-scroll overscrollable #imageCarouselRef>

    <!--<ons-carousel-item  style="background-color: #373B44;">-->
      <!--<img src="{{IMAGES_PATH + partyPics[currentPartyPicIndex]}}">-->
    <!--</ons-carousel-item>-->
    <ons-carousel-item *ngFor="let pictureName of partyPics; let i = index;">
      <img *ngIf="(currentPartyPicIndex === i) || (currentPartyPicIndex - 1 === i) || (currentPartyPicIndex + 1 === i)" src="{{IMAGES_PATH + partyPics[i]}}" (load)="onCarouselImgLoaded($event)">
    </ons-carousel-item>
    <!--<ons-carousel-item style="background-color: #D38312;">-->
      <!--<img src="{{IMAGES_PATH + partyPics[currentPartyPicIndex]}}">-->
    <!--</ons-carousel-item>-->
  </ons-carousel>
