import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from '@app/shared/breadcrumb/breadcrumb.component';
import { BlankComponent } from '@app/shared/layouts/blank/blank.component';
import { FullComponent } from '@app/shared/layouts/full/full.component';
import { SpinnerComponent } from '@app/shared/spinner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonLoaderComponent } from '@app/shared/button-loader';
import { FormErrorWrapperComponent } from '@app/shared/form-error-wrapper/form-error-wrapper.component';
import {OnsenModule} from "ngx-onsenui";
import {MenuService} from "@shared/menu/menu.service";
import {MyTeamComponent} from "@shared/menu/myteam/myteam.component";
import {ActionsComponent} from "@shared/menu/actions/actions.component";
import {ArtistsComponent} from "@shared/menu/artists/artists.component";
import {InformationComponent} from "@shared/menu/information/information.component";
import {MapComponent} from "@shared/menu/map/map.component";
import {PartyPicsComponent} from "@shared/menu/partypics/partypics.component";
import {ViewPictureComponent} from "@shared/menu/addpicture/view-picture.component";
import {AfterIfDirective} from "@shared/directives/after-if.directive";
import {FieldComponent} from "@shared/menu/field/field.component";
import {PriceListComponent} from "@shared/menu/pricelist/pricelist.component";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import {LoginComponent} from "@shared/login/login.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule.forRoot(),
    ReactiveFormsModule,
    OnsenModule,
    LeafletModule,
  ],
  declarations: [
    SpinnerComponent,
    BreadcrumbComponent,
    BlankComponent,
    FullComponent,
    MyTeamComponent,
    ActionsComponent,
    ArtistsComponent,
    LoginComponent,
    FieldComponent,
    InformationComponent,
    MapComponent,
    PartyPicsComponent,
    PriceListComponent,
    ViewPictureComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent,
    AfterIfDirective,
  ],
  exports: [
    SpinnerComponent,
    BreadcrumbComponent,
    BlankComponent,
    FullComponent,
    MyTeamComponent,
    ActionsComponent,
    ArtistsComponent,
    FieldComponent,
    LoginComponent,
    InformationComponent,
    MapComponent,
    PartyPicsComponent,
    PriceListComponent,
    ViewPictureComponent,
    ButtonLoaderComponent,
    FormErrorWrapperComponent
  ],
  entryComponents: [FullComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
