import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";
import {Constants} from "@app/core";
import {Field, Team, TeamEvent} from "@core/model/team.model";
import MainUtils from "@app/utils/mainutils";
import {TeamManager} from "@core/model/team.manager";

@Component({
  selector: 'my-team-component',
  templateUrl: './myteam.component.html',
  styleUrls: ['./myteam.component.scss']
})
export class MyTeamComponent implements OnInit {
  public selectedTeam: Team;
  public allMatchesOfTeam: TeamEvent[] = null;

  get Teams(): Team[] {
    return Constants.TEAMS;
  }
  get Fields(): Field[] {
    return Constants.FIELDS;
  }

  get SelectedTeam(): Team {
    if(this.selectedTeam)
      return this.selectedTeam;

    if(this.selectedTeam){
      return this.selectedTeam;
    }
    return null;
  }
  get SelectedTeamName(): string {
    let selectedTeamInternal = this.SelectedTeam;

    if(selectedTeamInternal){
      return this.selectedTeam.name;
    }
    return "-";
  }

  get currentHours(): number {
    return new Date().getHours();
  }

  constructor(public router: Router) {}

  ngOnInit() {
    let possibleTeamId = localStorage.getItem('previousSelectedTeam');
    this.trySetTeam(possibleTeamId)
  }

  private trySetTeam(possibleTeamId: any) {
    if(possibleTeamId && MainUtils.isNumber(possibleTeamId) && +possibleTeamId > -1) {
      let teamId = +possibleTeamId;
      this.selectedTeam = this.Teams.filter((t) => t.id === teamId)[0];
      this.loadTeam(teamId);
    } else {
      this.allMatchesOfTeam = null;
      this.selectedTeam = null;
    }
  }
  public onTeamChange(e: any) {
    this.trySetTeam(e.target ? e.target.value : null)
  }

  public getFieldNameFromId(fieldId: number): string {
    let foundField: Field = this.Fields.filter((f) => { return f.id === fieldId})[0];
    if(foundField)
      return foundField.name;
  }

  public getTeamNameFromId(teamId: number): string {
    let foundField: Field = this.Teams.filter((team) => { return team.id === teamId})[0];
    if(foundField)
      return foundField.name;
  }

  public getOpponentNameFromMatch(match: TeamEvent): string {
    if(this.selectedTeam) {
      let opponentTeamId = match.team1Id === this.selectedTeam.id ? match.team2Id : match.team1Id;
      let foundField: Field = this.Teams.filter((team) => { return team.id === opponentTeamId})[0];
      if(foundField)
        return foundField.name;
    }
    return "-";
  }

  private loadTeam(teamId: number) {
    localStorage.setItem('previousSelectedTeam', teamId.toString());
    this.allMatchesOfTeam = TeamManager.getAllMatchesOfTeam(teamId);
  }
}
