import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  @ViewChild('leftMenuRef') leftMenuRef: ElementRef;

  title:string = 'La Copa De Maastricht 2019';

  constructor(public router: Router) {}

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/myteam']);
    }
  }

  //constructor(private menuService: MenuService) { }
  openMenu() {
    //this.menuService.open();
  }

  alert() {
    ons.notification.alert('Hello, world!');
  }

  onMyTeamClick() {
    this.router.navigate(['/myteam']);
    this.leftMenuRef.nativeElement.close();
  }

  onFieldClick() {
    this.router.navigate(['/field']);
    this.leftMenuRef.nativeElement.close();
  }

  onTopImageMenuClick() {
    this.router.navigate(['/myteam']);
    this.leftMenuRef.nativeElement.close();
  }

  onMapClick() {
    this.router.navigate(['/map']);
    this.leftMenuRef.nativeElement.close();
  }

  onPriceListClick() {
    this.router.navigate(['/pricelist']);
    this.leftMenuRef.nativeElement.close();
  }

  onActionsClick() {
    this.router.navigate(['/actions']);
    this.leftMenuRef.nativeElement.close();
  }

  onPartyPicsClick() {
    this.router.navigate(['/partypics']);
    this.leftMenuRef.nativeElement.close();
  }

  onArtistsClick() {
    this.router.navigate(['/artists']);
    this.leftMenuRef.nativeElement.close();
  }

  onInformationClick() {
    this.router.navigate(['/information']);
    this.leftMenuRef.nativeElement.close();
  }
}
