import {Constants} from "@app/core";
import {Field, TeamEvent} from "@core/model/team.model";

export class TeamManager {
  static getAllMatchesOfField(fieldId: number): TeamEvent[] {
    return Constants.MATCHES.filter((match) => {
      return match.fieldId === fieldId;
    }).sort(this.sortTeamEventsByStartingTime);
  }
  static sortTeamEventsByStartingTime(a: any,b: any){
    return a["startingHour"] - b["startingHour"] || a["startingMinute"] - b["startingMinute"];
  }
  static getAllMatchesOfTeam(teamId: number): TeamEvent[] {
    return Constants.MATCHES.filter((match) => {
      return match.team1Id === teamId || match.team2Id === teamId;
    }).sort(this.sortTeamEventsByStartingTime);
  }


}
