import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, Subject, ReplaySubject} from "rxjs";
import * as ons from "onsenui";
import {ImageService} from "@core/service/image.service";
import { environment } from '@env/environment';

@Component({
  selector: 'partypics-component',
  templateUrl: './partypics.component.html',
  styleUrls: ['./partypics.component.scss']
})

//http://anssiko.github.io/html-media-capture/
export class PartyPicsComponent implements OnInit {
  public THUMBS_PATH = environment.serverUrl + "thumbs/";

  public showTakeImageComponent: boolean = false;
  public partyPics: string[] = [];
  public showTakeImageButton = false;
  public currentPartyPicIndex: number = -1;

  constructor(public router: Router, public imageService: ImageService) {}

  public ngOnInit(): void {
    this.getAllPartyPics();
    this.showTakeImageButton = ons.platform.isAndroid() || ons.platform.isIOS();
  }

  private getAllPartyPics(){
    this.imageService.getAllImages().subscribe(
      (result: any)=>{
        if(result && result.result) {
          this.partyPics = [];
          for(let i = 0; i < result.result.length; i++){
            this.partyPics.push(result.result[i]);
          }
        }
        // console.log('this.imageService.getAllImages().subscribe(');
        // console.log(this.partyPics);
      },
      (error) => {

      }
    );
  }

  public onRequestTakePhotoClick() {
    this.showTakeImageComponent = true;
  }

  public webcamHasFinished(finishedSuccesfully: boolean) {
    this.showTakeImageComponent = false;
    ons.notification.toast('De foto is met success genomen, ga nu snel bier drinken!', {timeout: 3000});
    this.getAllPartyPics();
  }

  setIndex() {

  }

  public onFileImageChanged(event: any) {
    let selectedFile: File = event.target.files[0];
    this.readBase64File(selectedFile).subscribe(
      (base64file: string) => {
        this.imageService.saveImage(base64file).subscribe(
          (result) => {
            ons.notification.toast('De foto is met success genomen, ga nu snel bier drinken!', {timeout: 3000});
            this.getAllPartyPics();
          },
          (error) => {
            ons.notification.toast('Upload misluk 01....', {timeout: 3000});
          }
        );
      },
      (error) => {
        ons.notification.toast('Upload mislukt 02....', {timeout: 3000});
      });
  }

  public readBase64File(fileToRead: File): Observable<string>{
    let base64Observable = new ReplaySubject<string>(1);

    let fileReader = new FileReader();
    fileReader.onload = event => {
      base64Observable.next(fileReader.result.toString());
    };
    fileReader.readAsDataURL(fileToRead);

    return base64Observable;
  }

  public onPartyImageClicked(index: number) {
    this.currentPartyPicIndex = index;
  }

  public onViewPictureFinished(success: boolean) {
    this.currentPartyPicIndex = -1;
  }
}
