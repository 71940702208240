<ons-page>
  <ons-card>
    <div class="title">
      Velden
    </div>
    <div class="content">
      <div class="label">
        Selecteer een veld
      </div>
      <ons-select modifier="underbar" (change)="onFieldChange($event)">
        <option value="-1" [selected]="!selectedField ? true : false">-</option>
        <option *ngFor="let field of Fields" [value]="field.id" [selected]="selectedField ? (selectedField.id === field.id) : false">{{field.name}}</option>
      </ons-select>
    </div>
  </ons-card>

  <ons-card *ngIf="allMatchesOfField">
    <div class="title">
      {{SelectedFieldName}}
    </div>
    <div class="content">
      <div class="label">* duur = in minuten</div><br />
      <ons-row class="header">
        <ons-col width="15%">Tijd</ons-col>
        <ons-col width="15%">Duur</ons-col>
        <ons-col>Team 1</ons-col>
        <ons-col>Team 2</ons-col>
      </ons-row>
      <ons-row *ngFor="let match of allMatchesOfField" [ngClass]="{'current-time': match.startingHour === currentHours}">
        <ons-col width="15%">{{match.startingHour}}:{{match.startingMinute.pad(2)}}</ons-col>
        <ons-col width="15%">{{match.durationInMinutes}}</ons-col>
        <ons-col>{{getTeamNameFromId(match.team1Id)}}</ons-col>
        <ons-col>{{getTeamNameFromId(match.team2Id)}}</ons-col>
      </ons-row>
    </div>
  </ons-card>
</ons-page>
