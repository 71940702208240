import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import * as ons from "onsenui";
import {circle, latLng, latLngBounds, LatLngBounds, marker, polygon, tileLayer} from "leaflet";
import * as L from 'leaflet';

@Component({
  selector: 'map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  constructor(public router: Router) {}

  ngOnInit() {
    this.setMap();
  }

  private setMap(){
    var map = L.map('mainmap', {
      crs: L.CRS.Simple,
      minZoom: -2,
      maxZoom: 0
    });

    var yx = L.latLng;

    var xy = function(x: any, y: any) {
      if (L.Util.isArray(x)) {    // When doing xy([x, y]);
        return yx(x[1], x[0]);
      }
      return yx(y, x);  // When doing xy(x, y);
    };

    //var bounds: LatLngBounds = latLngBounds([xy(-25, -26.5), xy(1023, 1021.5)]);
    var bounds: LatLngBounds = latLngBounds([xy(0,0), xy(3000, 2000)]);
    var image = L.imageOverlay('assets/images/map.png', bounds).addTo(map);

    var field1 = xy(800, 200); // fm kaffeee *

    var field2 = xy(1200, 1500); //integrand *

    var field3 = xy(2750, 200); //carnal *

    var field4 = xy(2100, 600); //beez *

    var field5 = xy(2650, 600); //beurs *

    var field6 = xy(1400, 200); //brand new day *

    var field7 = xy(2100, 200);


    L.marker(field1).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 1]);
      });
    L.marker(field2).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 2]);
      });
    L.marker(field3).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 3]);
      });
    L.marker(field4).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 4]);
      });
    L.marker(field5).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 5]);
      });
    L.marker(field6).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 6]);
      });
    L.marker(field7).addTo(map) /*.bindPopup('Sol')*/
      .on('click', () => {
        this.router.navigate(['/field', 7]);
      });

    //var travel = L.polyline([field1, field2]).addTo(map);

    map.setView(xy(1200, 700), -3);

    console.log("map set!");
  }
}
